import React, { useState, useEffect } from 'react';
import GlobalStore, { LOGIN } from '../../store/global-store';
import '../../vendors/css/admin.css';
import API from '../../lib/api';
import AdminMain from './main';

const Admin = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const store = GlobalStore.useContainer();

  useEffect(() => {
    if (!store.userState.isLoggedIn) {
      store.isLoggedIn();
    }
  }, [store]);

  const handleLoginClicked = async () => {
    if (!userId || !password) {
      return window.alert('ID 또는 비밀번호를 입력하세요.');
    }

    const res = await API.user.login(userId, password);

    if (res.result) {
      localStorage.setItem('sessionId', res.result.sessionId);
      store.dispatchUserAction({
        type: LOGIN,
        payload: {
          userId: res.result.userData.userId,
          userIdx: res.result.userData.userIdx,
          groups: res.result.userData.groups,
        },
      });
    } else {
      window.alert(res.msg);
    }
  };

  return (
    <div className="admin">
      <style type="text/css">{`
        body {
          background: #303030
        }
      `}</style>
      {store.userState.isLoggedIn ? (
        <AdminMain />
      ) : (
        <div className="login">
          <h1>GS안과 연예인 찾기 관리자페이지</h1>
          <div className="login-form">
            <input type="text" placeholder="ID 입력" value={userId} onChange={(e) => setUserId(e.target.value)} />
            <input
              type="password"
              placeholder="비밀번호 (4~12 자리)"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="btn-area">
            <button className="btn" onClick={handleLoginClicked}>
              로그인
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
