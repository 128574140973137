import React, { useState, useEffect } from 'react';
import logo from '../../vendors/img/img_admin_logo.png';
import AdminList from './list';
import AdminForm from './form';
import GlobalStore, { LOGOUT } from '../../store/global-store';

const AdminMain = () => {
  const [currentMenu, setCurrentMenu] = useState('list');
  const [editCeleb, setEditCeleb] = useState<null | ICelebInfo>(null);

  const store = GlobalStore.useContainer();

  useEffect(() => {
    if (editCeleb !== null) {
      setCurrentMenu('edit');
    }
  }, [editCeleb]);

  const handleMenuClick = (menu: string) => {
    setCurrentMenu(menu);
    setEditCeleb(null);
  };

  const handleLogout = () => {
    if (!window.confirm('로그아웃 하시겠어요?')) {
      return;
    }

    store.dispatchUserAction({ type: LOGOUT });
  };

  return (
    <div className="admin-layout">
      <div className="lnb">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <ul className="menu">
          <li className={currentMenu === 'list' ? 'selected' : ''} onClick={() => handleMenuClick('list')}>
            <span>연예인 관리</span>
          </li>
          <li className={currentMenu === 'add' ? 'selected' : ''} onClick={() => handleMenuClick('add')}>
            <span>연예인 등록</span>
          </li>
        </ul>
        <button onClick={() => handleLogout()} className="logout">
          로그아웃
        </button>
      </div>
      <div className="content">
        {currentMenu === 'list' ? (
          <AdminList onEdit={(celeb) => setEditCeleb(celeb)} />
        ) : (
          <AdminForm data={editCeleb} onComplete={() => handleMenuClick('list')} />
        )}
      </div>
    </div>
  );
};

export default AdminMain;
