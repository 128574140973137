import React, { useEffect, useState } from 'react';
import img0 from '../../vendors/img/process2_0.png';
import GlobalStore from '../../store/global-store';

const Process2 = () => {
  const [selected, setSelected] = useState('');
  const store = GlobalStore.useContainer();

  const data = [
    {
      name: '골든스마일',
      value: 'GOLDEN_SMILE',
    },
    {
      name: '라식·라섹 수술',
      value: 'LASIK_LASEK',
    },
    {
      name: '안내렌즈삽입술',
      value: 'INNER_LENSE',
    },
    {
      name: '기억이 안나요',
      value: 'OTHER',
    },
  ];

  useEffect(() => {
    if (selected && !store.applyData?.question2) {
      store.setApplyData({
        ...store.applyData,
        question2: selected,
      });

      setTimeout(() => store.setStep(3), 300);
    }
  }, [selected, store]);

  return (
    <div className="process2">
      <div className="process2_0">
        <img src={img0} alt="" />
      </div>
      <div className="process2_1">
        <ul>
          {data.map((dataItem, i) => {
            return (
              <li key={i}>
                <button
                  className={`${selected === dataItem.value ? 'selected' : ''}`}
                  onClick={() => setSelected(dataItem.value)}
                >
                  {dataItem.name}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Process2;
