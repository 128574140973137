import React, { useEffect, useState } from 'react';
import img0 from '../../vendors/img/process4_0.png';
import imgp from '../../vendors/img/imgp.gif';
import img2 from '../../vendors/img/process4_2.png';
import GlobalStore from '../../store/global-store';
import API from '../../lib/api';
import { differenceInCalendarDays } from 'date-fns';
import { pad } from '../../lib/api/utils';

const Process4 = () => {
  const [isLoad, setIsLoad] = useState(false);
  const store = GlobalStore.useContainer();

  useEffect(() => {
    const fetchInfo = async () => {
      if (!store.applyData) {
        return;
      }

      const [month, day] = (store.applyData?.question3 || '').split('/');
      const res = await API.celeb.info(store.applyData);

      if (res.code === 200 && res.result) {
        let nearCeleb: null | ICelebInfo = null;
        res.result.forEach((row) => {
          const diffDays = Math.abs(
            differenceInCalendarDays(
              new Date(`${store.applyData?.question1}-${pad(parseInt(month))}-${pad(parseInt(day))}`),
              new Date(row.operate_date.replace(/-/g, '/'))
            )
          );
          if (!nearCeleb) {
            nearCeleb = row;
          } else {
            const nearDiffDays = Math.abs(
              differenceInCalendarDays(
                new Date(`${store.applyData?.question1}-${pad(parseInt(month))}-${pad(parseInt(day))}`),
                new Date(nearCeleb.operate_date.replace(/-/g, '/'))
              )
            );
            if (nearDiffDays > diffDays) {
              nearCeleb = row;
            }
          }
        });

        store.setResultInfo(nearCeleb);
      }

      if (store.searchParams.get('question1')) {
        store.setStep(5);
      } else {
        setTimeout(() => store.setStep(5), 1000 * 5);
      }
    };

    if (!isLoad && !store.resultInfo) {
      setIsLoad(true);
      fetchInfo();
    }
  }, [isLoad, store]);

  return (
    <div className="process4">
      <div className="process4_0">
        <img src={img0} alt="" />
      </div>
      <div className="process4_1">
        <img src={imgp} alt="" />
      </div>
      <div className="process4_2">
        <img src={img2} alt="" />
      </div>
    </div>
  );
};

export default Process4;
