import { fetch, METHOD } from './api-utils';

const CelebApiList = {
  info: (applyData: IV1Apply): Promise<IRes<ICelebInfo[]>> => {
    return fetch<ICelebInfo[]>(METHOD.GET, '/celeb/info', {
      ...applyData,
    });
  },

  getPrevNext: (type: 'prev' | 'next', operateType: string, currentIdx: number): Promise<IRes<ICelebInfo>> => {
    return fetch<ICelebInfo>(METHOD.GET, '/celeb/prevnext', {
      type,
      operateType,
      currentIdx,
    });
  },

  list: (): Promise<IRes<ICelebInfo[]>> => {
    return fetch<ICelebInfo[]>(METHOD.GET, '/celeb/list', {});
  },

  pageList: (page: number, keyword?: string): Promise<IRes<{ list: ICelebInfo[]; total: number }>> => {
    return fetch<{ list: ICelebInfo[]; total: number }>(METHOD.GET, '/celeb/page-list', { page, keyword });
  },

  remove: (celebIdx: number): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.POST, '/celeb/delete', { celebIdx });
  },

  save: (formData: FormData): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.POST, '/celeb/save', formData, true);
  },
};

export default CelebApiList;
