import React from 'react';
import GlobalStore from '../store/global-store';
import Process0 from '../components/process/process0';
import Process1 from '../components/process/process1';
import Process2 from '../components/process/process2';
import Process3 from '../components/process/process3';
import Process4 from '../components/process/process4';
import Process5 from '../components/process/process5';
import Process6 from '../components/process/process6';

const Index = () => {
  const store = GlobalStore.useContainer();
  const renderSwitch = () => {
    switch (store.step) {
      case 0:
        return <Process0 />;
      case 1:
        return <Process1 />;
      case 2:
        return <Process2 />;
      case 3:
        return <Process3 />;
      case 4:
        return <Process4 />;
      case 5:
        return <Process5 />;
      case 6:
        return <Process6 />;
    }
  };

  return <div className="process">{renderSwitch()}</div>;
};

export default Index;
