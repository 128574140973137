import React, { useState, useEffect, useRef } from 'react';
import API from '../../lib/api';
import Pagination from '../common/pagination';
import { format } from 'date-fns';

const OPERATE_TYPE: Record<string, string> = {
  GOLDEN_SMILE: '골든 스마일',
  LASIK_LASEK: '라식·라섹 수술',
  INNER_LENSE: '안내렌즈 삽입술',
  OTHER: '기억이 안나요',
};

const AdminList = ({ onEdit }: { onEdit: (celeb: ICelebInfo) => void }) => {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState<ICelebInfo[]>([]);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetch = async () => {
      const res = await API.celeb.pageList(page, keyword);
      if (res.code === 200 && res.result) {
        setList(res.result.list);
        setTotal(res.result.total);
      }
    };

    fetch();
  }, [page, keyword, refresh]);

  const handleSearch = () => {
    if (!searchInputRef.current) {
      return;
    }

    setKeyword(searchInputRef.current.value);
    setPage(1);
  };

  const handleDelete = async (item: ICelebInfo) => {
    if (!window.confirm(`${item.name}을 삭제하시겠어요? 복구할 수 없습니다.`)) {
      return;
    }

    const res = await API.celeb.remove(item.celeb_idx);
    if (res.code === 200) {
      setPage(1);
      setRefresh(refresh + 1);
    }
  };

  return (
    <div className="admin-list">
      <div className="list-wrap">
        <div className="table-wrap">
          <table>
            <colgroup>
              <col width="100px" />
              <col width="*" />
              <col width="200px" />
              <col width="150px" />
              <col width="150px" />
            </colgroup>
            <thead>
              <tr>
                <th>프로필 사진</th>
                <th>이름</th>
                <th>수술일자</th>
                <th>수술종류</th>
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {list.map((row, i) => (
                <tr key={i}>
                  <td>
                    <img
                      className="profile"
                      src={`https://api.gseyecenter.com/upload/${row.img_thumbnail_src}`}
                      alt=""
                    />
                  </td>
                  <td>{row.name}</td>
                  <td>{format(new Date(row.operate_date.replace(/-/g, '/')), 'yyyy-MM-dd')}</td>
                  <td>{OPERATE_TYPE[row.operate_type as string]}</td>
                  <td>
                    <button className="btn btn-edit" onClick={() => onEdit(row)}>
                      수정
                    </button>
                    <button className="btn btn-delete" onClick={() => handleDelete(row)}>
                      삭제
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="search-wrap">
            <input
              type="text"
              className="search"
              ref={searchInputRef}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSearch();
                }
              }}
            />
            <button className="btn" onClick={() => handleSearch()}>
              검색
            </button>
          </div>
          <Pagination currentPage={page} onPageClicked={(page) => setPage(page)} totalPageCount={total} />
        </div>
      </div>
    </div>
  );
};

export default AdminList;
