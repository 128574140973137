import { createContainer } from 'unstated-next';
import { useReducer, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const LOCAL_STORAGE_KEY = 'gs_account_info';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

interface ILoginAction {
  type: typeof LOGIN;
  payload: MyUserInfo;
}

interface ILogoutAction {
  type: typeof LOGOUT;
}

interface IUserState {
  isLoggedIn: boolean;
}

type UserActionType = ILoginAction | ILogoutAction;

interface IUserState extends MyUserInfo {
  isLoggedIn: boolean;
}

const initialUserState: IUserState = {
  isLoggedIn: false,
  userId: '',
  userIdx: -1,
  groups: '',
};

const userReducer = (state: IUserState, action: UserActionType) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(action.payload));
      return { ...state, isLoggedIn: true, ...action.payload };
    case LOGOUT:
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return { ...initialUserState, isLoggedIn: false };
    default:
      return state;
  }
};

const useGlobal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userState, dispatchUserAction] = useReducer(userReducer, initialUserState);
  const [applyData, setApplyData] = useState<null | IV1Apply>(null);
  const [resultInfo, setResultInfo] = useState<null | ICelebInfo>(null);
  const [step, setStep] = useState(0);

  useEffect(() => {
    return () => setStep(0);
  }, []);

  useEffect(() => {
    if (searchParams.get('question1') && searchParams.get('question2') && searchParams.get('question3')) {
      setStep(4);
      setApplyData({
        question1: searchParams.get('question1') as string,
        question2: searchParams.get('question2') as string,
        question3: searchParams.get('question3') as string,
      });
    }
  }, [searchParams]);

  const isLoggedIn = () => {
    if (!userState.isLoggedIn) {
      const savedInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedInfo) {
        dispatchUserAction({ type: LOGIN, payload: JSON.parse(savedInfo) });
        return true;
      }
    }

    return userState.isLoggedIn;
  };

  const updateApplyData = (key: string, value: string | number) => {
    setApplyData({
      ...(applyData || {}),
      [key]: value,
    });
  };

  return {
    userState,
    dispatchUserAction,
    isLoggedIn,

    step,
    setStep,
    searchParams,
    setSearchParams,
    applyData,
    setApplyData,
    resultInfo,
    setResultInfo,
    updateApplyData,
  };
};

const GlobalStore = createContainer(useGlobal);

export default GlobalStore;
