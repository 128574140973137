import { fetch, METHOD } from './api-utils';

const UserApiList = {
  login: (userId: string, password: string): Promise<IRes<IUserLoginRes>> => {
    return fetch<IUserLoginRes>(METHOD.POST, '/user/login', {
      userId,
      password,
      groups: 'celeb',
    });
  },

  logout: (): Promise<IRes<undefined>> => {
    localStorage.removeItem('sessionId');
    return fetch<undefined>(METHOD.GET, '/user/logout', {});
  },

  isLogin: (): Promise<IRes<{ result: boolean }>> => {
    return fetch<{ result: boolean }>(METHOD.GET, '/user/is-login', {});
  },

  setPassword: (groups: string, password: string): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.POST, '/user/set-password', { groups, password });
  },

  setTempPassword: (groups: string): Promise<IRes<undefined>> => {
    return fetch<undefined>(METHOD.POST, '/user/set-temp-password', { groups });
  },
};

export default UserApiList;
