import React, { useEffect, useState } from 'react';
import img0 from '../../vendors/img/process3_0.png';
import GlobalStore from '../../store/global-store';

const Process3 = () => {
  const [month, setMonth] = useState<'' | number>('');
  const [day, setDay] = useState<'' | number>('');

  const store = GlobalStore.useContainer();

  useEffect(() => {
    if (month && day && !store.applyData?.question3) {
      store.setApplyData({
        ...store.applyData,
        question3: month + '/' + day,
      });
      setTimeout(() => store.setStep(4), 300);
    }
  }, [month, day, store]);

  return (
    <div className="process3">
      <div className="process3_0">
        <img src={img0} alt="" />
      </div>
      <div className="process3_1">
        <ul>
          <li>
            <select
              className={month ? 'selected' : ''}
              value={month}
              onChange={(e) => setMonth(parseInt(e.target.value))}
            >
              <option disabled value="">
                월
              </option>
              {new Array(12).fill(0).map((row, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}월
                </option>
              ))}
            </select>
          </li>
          <li>
            <select className={day ? 'selected' : ''} value={day} onChange={(e) => setDay(parseInt(e.target.value))}>
              <option disabled value="">
                일
              </option>
              {new Array(31).fill(0).map((row, i) => (
                <option key={i} value={i + 1}>
                  {i + 1}일
                </option>
              ))}
            </select>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Process3;
