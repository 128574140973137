import React from 'react';
import img0 from '../../vendors/img/process0_0.png';
import img1 from '../../vendors/img/process0_1.png';
import img2 from '../../vendors/img/process0_2.png';
import GlobalStore from '../../store/global-store';

const Process0 = () => {
  const store = GlobalStore.useContainer();
  return (
    <div className="process0">
      <div className="process0_0">
        <img src={img0} alt="" />
      </div>
      <div className="process0_1">
        <img src={img1} alt="" onClick={() => store.setStep(1)} />
      </div>
      <div className="process0_2">
        <img src={img2} alt="" />
      </div>
    </div>
  );
};

export default Process0;
