import UserApiList from './user-api-list';
import CommonApiList from './common-api-list';
import CelebApiList from './celeb-api-list';

const API = {
  user: UserApiList,
  common: CommonApiList,
  celeb: CelebApiList,
};

export default API;
