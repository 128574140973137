import axios, { AxiosError, AxiosRequestConfig } from 'axios';

axios.defaults.baseURL = 'https://api.gseyecenter.com';
// axios.defaults.baseURL = 'http://gseye-api.local';
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export enum METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
}

export const fetch = async <T>(
  method: METHOD,
  path: string,
  params: object | FormData | null,
  isMultipart?: boolean
): Promise<IRes<T>> => {
  const axiosConfig: AxiosRequestConfig = {
    method,
    url: path,
  };

  const setSessionId = (params: object | FormData | null) => {
    const sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      return params;
    }

    if (!params) {
      return sessionId ? { sessionId } : {};
    }

    if (params instanceof FormData) {
      params.append('sessionId', sessionId);
      return params;
    }

    return {
      ...params,
      sessionId,
    };
  };

  if (isMultipart) {
    axiosConfig.headers = {
      ...axiosConfig.headers,
      'Content-Type': 'multipart/form-data',
    };
  }

  if (method !== METHOD.GET) {
    axiosConfig.data = setSessionId(params);
  }

  if (method === METHOD.GET) {
    axiosConfig.params = setSessionId(params);
  }

  try {
    const res = await axios(axiosConfig);

    return {
      code: res.data.code,
      msg: res.data.msg,
      result: res.data.result,
    };
  } catch (err) {
    const axiosError = err as AxiosError;
    const response = axiosError.response;
    return {
      code: response ? response?.status : -1,
      msg: response?.statusText || 'Unknown error',
    };
  }
};
