import React, { useState, useEffect } from 'react';
import img0 from '../../vendors/img/process6_0.png';
import API from '../../lib/api';

const Process6 = () => {
  const [list, setList] = useState<ICelebInfo[]>([]);
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await API.celeb.list();
      if (res.code === 200 && res.result) {
        setList(res.result);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    setYears(
      new Array(new Date().getFullYear() - 2018).fill(0).map((row, i) => {
        return new Date().getFullYear() - i;
      })
    );
  }, []);

  const getCelebLists = (year: number) => {
    return (
      list.filter(
        (row) =>
          new Date(row.operate_date.replace(/-/g, '/')).getFullYear() === year ||
          (year === 2019 && new Date(row.operate_date.replace(/-/g, '/')).getFullYear() <= 2019)
      ) || []
    ).sort((a, b) =>
      parseInt(a.list_order, 10) < parseInt(b.list_order, 10)
        ? 1
        : parseInt(a.list_order, 10) > parseInt(b.list_order, 10)
        ? -1
        : 0
    );
  };

  const handleRemoveHide = (index: number) => {
    const wrap = document.getElementById(`celeb${index}`);
    if (!wrap) {
      return;
    }

    const button = wrap.querySelector('button');
    if (button) {
      button.parentElement?.removeChild(button);
    }

    wrap.querySelectorAll('.hide').forEach((element) => {
      element.classList.remove('hide');
    });
  };

  return (
    <div className="process6">
      <div className="process6_0">
        <img src={img0} alt="" />
      </div>
      {years.map((year, i) => {
        const celebList = getCelebLists(year);
        return celebList.length > 0 ? (
          <div className="process6_group" key={i} id={`celeb${i}`}>
            <div className="process6_group_title">
              <span>{year > 2019 ? `${year}년` : '2019년 이전'}</span>
            </div>
            <ul>
              {celebList.map((celeb, j) => (
                <li key={j} className={j > 2 ? 'hide' : ''}>
                  <div
                    className="profile"
                    style={{ backgroundImage: `url('https://api.gseyecenter.com/upload/${celeb.img_thumbnail_src}')` }}
                  />
                  <div className="name">{celeb.name}</div>
                </li>
              ))}
            </ul>
            {celebList.length > 3 && (
              <button className="more-btn" onClick={() => handleRemoveHide(i)}>
                더보기 +
              </button>
            )}
          </div>
        ) : (
          <React.Fragment key={i} />
        );
      })}
    </div>
  );
};

export default Process6;
