import React, { useState, useEffect } from 'react';
import img0 from '../../vendors/img/process5_0.png';
import img2 from '../../vendors/img/process5_bottom.png';
import snsImg from '../../vendors/img/sns.png';
import button0 from '../../vendors/img/process5_1.png';
import button1 from '../../vendors/img/process5_2.png';
import GlobalStore from '../../store/global-store';
import { differenceInCalendarDays } from 'date-fns';
import { pad } from '../../lib/api/utils';
import copy from 'copy-to-clipboard';
import API from '../../lib/api';

const TITLE = {
  TODAY: '"와~ 같은날?!\n혹시 우연히 마주친거 아냐?!',
  ONE_DAY: '와 딱 하루 차이,\n진짜 아깝다!',
  WEEK: '며칠만 날짜조정했으면\n만났을지도?!',
  MONTH_BEFORE: '내가 먼저\n수술했더라도 ㅠㅜ',
  MONTH_AFTER: '아쉽다ㅜㅠ\n그래도 내가 먼저 수술했지롱!',
  THREE_MONTH: '날짜 차이가 꽤 나네\n수술날짜를 조절할걸 그랬나?!',
};

declare global {
  interface Window {
    Kakao: any;
  }
}

window.Kakao = window.Kakao || 'SomeValue';
const Process5 = () => {
  const [diffDays, setDiffDays] = useState(0);
  const [isSharePopup, setIsSharePopup] = useState(false);

  const store = GlobalStore.useContainer();

  const renderTitle = () => {
    if (!store.resultInfo?.operate_date) {
      return <>-</>;
    }

    const compareDiffDays = Math.abs(diffDays);

    if (compareDiffDays === 0) {
      return <>{TITLE.TODAY}</>;
    } else if (compareDiffDays === 1) {
      return <>{TITLE.ONE_DAY}</>;
    } else if (compareDiffDays <= 7) {
      return <>{TITLE.WEEK}</>;
    } else if (diffDays > 0 && diffDays <= 60) {
      return <>{TITLE.MONTH_BEFORE}</>;
    } else if (diffDays < 0 && diffDays >= -60) {
      return <>{TITLE.MONTH_AFTER}</>;
    } else {
      return <>{TITLE.THREE_MONTH}</>;
    }
  };

  // const getMyOperateDate = () => {
  //   const [month, day] = (store.applyData?.question3 || '').split('/');
  //   return `${store.applyData?.question1}년 ${pad(parseInt(month))}월 ${pad(parseInt(day))}일`;
  // };

  useEffect(() => {
    const [month, day] = (store.applyData?.question3 || '').split('/');
    if (store.resultInfo) {
      const diffDays = differenceInCalendarDays(
        new Date(`${store.applyData?.question1}-${pad(parseInt(month, 10))}-${pad(parseInt(day, 10))}`),
        new Date(store.resultInfo?.operate_date.replace(/-/g, '/'))
      );
      setDiffDays(diffDays);
    }
  }, [store.resultInfo, store.applyData]);

  const handleCopy = (type: 'kakao' | 'twitter' | 'facebook' | 'copy') => {
    if (!store.applyData) {
      return;
    }

    const copyLink =
      window.location.href + '?' + new URLSearchParams(store.applyData as Record<string, string>).toString();
    switch (type) {
      case 'copy':
        copy(copyLink);
        window.alert('복사 되었습니다.');
        break;
      case 'kakao':
        // eslint-disable-next-line no-undef
        if (window?.Kakao) {
          window?.Kakao.Link.sendDefault({
            objectType: 'text',
            text: '같은 날 수술한 셀럽찾기',
            link: {
              mobileWebUrl: copyLink,
              webUrl: copyLink,
            },
          });
        }
        break;
      case 'facebook':
        window.location.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(copyLink)}`;
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            '같은날 수술한 셀럽찾기'
          )}&url=${encodeURIComponent(copyLink)}`
        );
    }
  };

  const handlePrevNext = async (type: 'prev' | 'next') => {
    if (!store.resultInfo || !store.applyData?.question2) {
      return;
    }

    const res = await API.celeb.getPrevNext(type, store.applyData?.question2, store.resultInfo?.celeb_idx);
    if (res.code === 200 && res.result) {
      store.setResultInfo(res.result);
      window.scrollTo(0, 0);
    } else {
      window.alert('이전/다음에 수술한 셀럽을 찾을 수 없었어요.');
    }
  };

  return store.resultInfo ? (
    <div className="process5">
      <div className="process5_0">
        <img src={img0} alt="" />
      </div>
      <div className="process5_1">{renderTitle()}</div>
      <div className="process5_2">
        <img src={`https://api.gseyecenter.com/upload/${store.resultInfo.img_description_src}`} alt="" />
      </div>
      <div className="process5_3">
        <h2>
          {store.resultInfo.name} {!!store.resultInfo.group_name.trim() && <span>({store.resultInfo.group_name})</span>}
        </h2>
        <ul>
          {/*<li>*/}
          {/*  <b>수술날짜: </b>*/}
          {/*  {formatDate(store.resultInfo.operate_date.replace(/-/g, '/'))}*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <b>당신의 수술날짜: </b>*/}
          {/*  {getMyOperateDate()}*/}
          {/*</li>*/}
          <li>
            <b>비껴간 시간: </b>
            {Math.abs(diffDays)}일
          </li>
        </ul>
      </div>
      {!!store.resultInfo.postscript && <div className="process5_4">{store.resultInfo.postscript}</div>}
      {!!store.resultInfo.description && <div className="process5_5">{store.resultInfo.description}</div>}
      <div className="process5_6">
        <img src={button0} alt="" onClick={() => handlePrevNext('prev')} />
        <img src={button1} alt="" onClick={() => handlePrevNext('next')} />
      </div>
      <div className="process5_7">
        <ul>
          <li>
            <button onClick={() => store.setStep(6)}>GS안과 수술 셀럽 전체보기</button>
          </li>
          <li>
            <button onClick={() => setIsSharePopup(true)}>친구에게 자랑하기</button>
          </li>
          <li>
            <button
              onClick={() => {
                store.setStep(0);
                store.setApplyData(null);
                store.setResultInfo(null);
              }}
            >
              다시 테스트하기
            </button>
          </li>
        </ul>
      </div>
      <div className="process5_8">
        <img src={img2} alt="" />
      </div>
      {isSharePopup && (
        <div className="process5_share_popup" onClick={() => setIsSharePopup(false)}>
          <div
            className="process5_share_popup_content"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className="share_buttons">
              <button
                className="share_button kakao"
                style={{ backgroundImage: `url("${snsImg}")` }}
                onClick={() => handleCopy('kakao')}
              >
                카카오 공유하기
              </button>
              <button
                className="share_button facebook"
                style={{ backgroundImage: `url("${snsImg}")` }}
                onClick={() => handleCopy('facebook')}
              >
                페이스북 공유하기
              </button>
              <button
                className="share_button twitter"
                style={{ backgroundImage: `url("${snsImg}")` }}
                onClick={() => handleCopy('twitter')}
              >
                트위터 공유하기
              </button>
            </div>
            <button className="share_link_button" onClick={() => handleCopy('copy')}>
              링크 복사
            </button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Process5;
