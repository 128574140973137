import React, { useEffect, useState } from 'react';
import img0 from '../../vendors/img/process1_0.png';
import GlobalStore from '../../store/global-store';

const Process1 = () => {
  const [selected, setSelected] = useState('');
  const store = GlobalStore.useContainer();

  useEffect(() => {
    if (selected && !store.applyData?.question1) {
      store.setApplyData({
        ...store.applyData,
        question1: selected,
      });

      setTimeout(() => store.setStep(2), 300);
    }
  }, [selected, store]);

  return (
    <div className="process1">
      <div className="process1_0">
        <img src={img0} alt="" />
      </div>
      <div className="process1_1">
        <ul>
          {new Array(new Date().getFullYear() - 2018).fill(0).map((row, i) => {
            const year = new Date().getFullYear() - i;
            const dataName = year.toString() + (i === 4 ? ':before' : '');
            return (
              <li key={i}>
                <button
                  className={`${selected === dataName ? 'selected' : ''}`}
                  onClick={() => setSelected(dataName)}
                >{`${year}년${i === 4 ? ' 이전' : ''}`}</button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Process1;
