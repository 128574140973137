import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import API from '../../lib/api';

const AdminForm = ({ data, onComplete }: { data?: ICelebInfo | null; onComplete: () => void }) => {
  const [formData, setFormData] = useState<Partial<ICelebInfo>>({});

  const imgThumbnailRef = useRef<HTMLInputElement>(null);
  const imgDescriptionRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFormData(data || {});
  }, [data]);

  const handleSaveClicked = async () => {
    if (!formData.name || !formData.group_name || !formData.postscript || !formData.description) {
      window.alert('필수 입력 값이 입력되지 않았습니다.');
      return;
    }

    if (
      !formData.celeb_idx &&
      ((imgThumbnailRef.current?.files || []).length < 1 || (imgDescriptionRef.current?.files || []).length < 1)
    ) {
      window.alert('신규 등록 시에는 이미지를 필수로 업로드 하셔야 됩니다 ');
      return;
    }

    const sendFormData = new FormData();

    if (formData.celeb_idx) {
      sendFormData.append('celeb_idx', formData.celeb_idx.toString());
    }

    sendFormData.append('name', formData.name);
    sendFormData.append('group_name', formData.group_name);
    sendFormData.append('postscript', formData.postscript);
    sendFormData.append('description', formData.description);
    sendFormData.append('operate_date', formData.operate_date || format(new Date(), 'yyyy-MM-dd 00:00:00'));
    sendFormData.append('operate_type', formData.operate_type || 'GOLDEN_SMILE');
    sendFormData.append('list_order', formData.list_order?.toString() || '0');

    if (imgThumbnailRef.current && (imgThumbnailRef.current.files || []).length > 0) {
      sendFormData.append('imgThumbnail', (imgThumbnailRef.current.files || [])[0]);
    }

    if (imgDescriptionRef.current && (imgDescriptionRef.current.files || []).length > 0) {
      sendFormData.append('imgDescription', (imgDescriptionRef.current.files || [])[0]);
    }

    const res = await API.celeb.save(sendFormData);
    if (res.code === 200) {
      window.alert('저장 되었습니다.');
      onComplete();
    }
  };

  return (
    <div className="edit-form">
      <h1>연예인 등록/수정</h1>
      <table>
        <tbody>
          <tr>
            <th>이름</th>
            <td>
              <input
                type="text"
                value={formData?.name || ''}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <th>그룹명</th>
            <td>
              <input
                type="text"
                value={formData?.group_name || ''}
                onChange={(e) => setFormData({ ...formData, group_name: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <th>프로필이미지</th>
            <td>
              {formData?.img_thumbnail_src && (
                <img src={`https://api.gseyecenter.com/upload/${formData?.img_thumbnail_src}`} alt="" />
              )}
              <input type="file" ref={imgThumbnailRef} /> (512*512)
            </td>
          </tr>
          <tr>
            <th>상세이미지</th>
            <td>
              {formData?.img_description_src && (
                <img src={`https://api.gseyecenter.com/upload/${formData?.img_description_src}`} alt="" />
              )}
              <input type="file" ref={imgDescriptionRef} /> (1280*720)
            </td>
          </tr>
          <tr>
            <th>수술후기</th>
            <td>
              <textarea
                value={formData?.postscript || ''}
                onChange={(e) => setFormData({ ...formData, postscript: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <th>연예인 상세</th>
            <td>
              <textarea
                value={formData?.description || ''}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <th>수술분류</th>
            <td>
              <select
                value={formData?.operate_type}
                onChange={(e) => setFormData({ ...formData, operate_type: e.target.value as OPERATION_TYPE })}
              >
                <option value="GOLDEN_SMILE">골든 스마일</option>
                <option value="LASIK_LASEK">라식·라섹 수술</option>
                <option value="INNER_LENSE">안내렌즈삽입술</option>
                <option value="OTHER">기억이 안나요</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>수술일</th>
            <td>
              <DatePicker
                selected={formData?.operate_date ? new Date(formData?.operate_date?.replace(/-/g, '/')) : new Date()}
                onChange={(date: Date) =>
                  setFormData({ ...formData, operate_date: format(date, 'yyyy-MM-dd 00:00:00') })
                }
                dateFormat="yyyy/MM/dd"
              />
            </td>
          </tr>
          <tr>
            <th>우선순위</th>
            <td>
              <input
                type="text"
                value={formData?.list_order || 0}
                onChange={(e) => setFormData({ ...formData, list_order: e.target.value })}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="btn-area">
        <button className="btn" onClick={handleSaveClicked}>
          저장
        </button>
      </div>
    </div>
  );
};

export default AdminForm;
